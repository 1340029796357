import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';

const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

@Injectable({
  providedIn: 'root'
})

export class AppService {
  env = environment; 
  constructor(private http: HttpClient,private meta: Meta) {}
  url =  this.env.baseOCCUrl+"/bgswebservices/v2/myboeingfleet/cms/pages/"

  getPageData(pageid:any){
    return this.http.get(this.url+pageid)
  }


  setPageMetaTags(response: any) {
    if (!response) return null;
    // low to high priority when have same name
    const metaTags = [
      { name: 'twitter:site', content: response.socialSite },
      { property: 'og:image', content: response.socialThumbnailDefault },
      { name: 'twitter:image', content: response.socialThumbnailDefault },
      { property: 'og:image', content: response.socialThumbnail },
      { name: 'twitter:image', content: response.socialThumbnail },
      { property: 'og:url', content: window.location.href },
      { property: 'og:url', content: response.socialUrl },
      { name: 'twitter:title', content: response.socialTitle },
      { property: 'og:title', content: response.socialTitle },
      { name: 'twitter:title', content: response.socialTitle },
    ]

    metaTags.forEach((tag: any) => { if (tag?.content?.length) { this.meta.updateTag(tag); } });


    if (response.label?.startsWith('/draft') || response.label?.startsWith('/drafts')){
      this.meta.updateTag({ name: 'robots', content: 'NOINDEX,NOFOLLOW' })
    }

  }


}
